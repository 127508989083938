import {App, Plugin} from 'vue';
import $script from 'scriptjs';

export const datadog: Plugin = {
  async install(
    app: App,
    {
      applicationId,
      clientToken,
      version,
      service,
      sampleRate = 0,
    }: {
      applicationId: string;
      clientToken: string;
      version: string;
      service: string;
      sampleRate: number;
    }
  ): Promise<void> {
    const initialiseDatadog = () => {
      if (window.DD_RUM === undefined) {
        return;
      }

      window.DD_RUM.onReady(() => {
        window.DD_RUM.init({
          clientToken,
          applicationId,
          site: 'datadoghq.eu',
          service,
          env: import.meta.env.PROD ? 'production' : 'development',
          version,
          sessionSampleRate: sampleRate,
          sessionReplaySampleRate: 0,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
      });
    };

    $script(
      'https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js',
      initialiseDatadog
    );
  },
};

// if (
//   process.env.NODE_ENV === 'production' &&
//   !isbot(navigator.userAgent)
// ) {

// }
